import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UrlParams from "./Utils/UrlParams";

import appConfig from 'Configs/app.config';

async function render() {
  const params = new UrlParams(window.location.search)
  appConfig.mockApi = params.get("demo") === "true"
  createRoot(document.getElementById('root'))
    .render(
      <StrictMode>
        <App/>
      </StrictMode>
    );
}

render()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
